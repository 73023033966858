import React from "react";
import { Box, Typography } from "@mui/material";

const alertStyles = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  padding: "8px",
  borderRadius: "4px",
  fontSize: "14px",
  fontFamily: "Quicksand-Regular",
  marginTop: "8px",
};

const alertConfigs = {
  datos_existentes: {
    backgroundColor: "#F3E8FF", // Fondo morado suave
    color: "#7C3AED", // Texto morado
    getMessage: (nombre) => ({
      title: `Bienvenido de nuevo ${nombre || ""}`,
      subtitle: "tus datos están listos para agendar",
    }),
  },
  paciente_nuevo: {
    backgroundColor: "#E3F2FD",
    color: "#0D47A1",
    icon: "✓",
    getMessage: () => ({
      title: "Identificación válida",
      subtitle: "ingrese sus datos para agendar",
    }),
  },
  rut_invalido: {
    backgroundColor: "#FFEBEE",
    color: "#C62828",
    icon: "⚠",
    getMessage: () => ({
      title: "Identificación inválida",
      subtitle: "Por favor intenta nuevamente",
    }),
  },
};

const CustomAlert = ({ type, nombre }) => {
  if (!type) return null;

  const config = alertConfigs[type];
  if (!config) return null;

  const message = config.getMessage(nombre);

  return (
    <Box
      sx={{
        ...alertStyles,
        backgroundColor: config.backgroundColor,
        color: config.color,
      }}
    >
      {type === "datos_existentes" && (
        <img
          src="/sparkles.svg"
          alt="Estrellas"
          style={{
            width: "22px",
            height: "22px",
            filter:
              "invert(35%) sepia(97%) saturate(3730%) hue-rotate(254deg) brightness(96%) contrast(93%)",
          }}
        />
      )}
      <span>{config.icon}</span>
      <Box>
        <Typography
          sx={{
            fontFamily: "Quicksand-Bold",
            fontSize: "15px",
            lineHeight: 1.2,
          }}
        >
          {message.title}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Quicksand-Regular",
            fontSize: "12px",
            lineHeight: 1.2,
          }}
        >
          {message.subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomAlert;
