import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Button, Paper } from "@mui/material";
import { isMobile } from "react-device-detect";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const CustomCalendar = ({ disponibilidad, onDateChange }) => {
  const [currentMonth, setCurrentMonth] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [nextMonthHasAvailability, setNextMonthHasAvailability] =
    useState(false);

  const getMonthName = (date) => {
    return date.toLocaleString("es-ES", { month: "long" });
  };

  const hasAvailableDatesInMonth = (date, dates) => {
    if (!dates || !dates.length) return false;
    return dates.some((availableDate) => {
      const [year, month] = availableDate.split("-").map(Number);
      return year === date.getFullYear() && month === date.getMonth() + 1;
    });
  };

  const checkAndUpdateMonth = (dates) => {
    const today = new Date();
    const currentMonthDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const nextMonthDate = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      1
    );

    const hasCurrentMonthDates = hasAvailableDatesInMonth(
      currentMonthDate,
      dates
    );

    const hasNextMonthDates = hasAvailableDatesInMonth(nextMonthDate, dates);

    setNextMonthHasAvailability(hasNextMonthDates);
    setShowAlert(!hasCurrentMonthDates);

    if (!hasCurrentMonthDates) {
      const hasNextMonthDates = hasAvailableDatesInMonth(nextMonthDate, dates);

      if (hasNextMonthDates) {
        setCurrentMonth(nextMonthDate);
      } else {
        setCurrentMonth(currentMonthDate);
      }
    } else {
      setCurrentMonth(currentMonthDate);
    }
  };

  useEffect(() => {
    if (disponibilidad) {
      const dates = Object.keys(disponibilidad);
      setAvailableDates(dates);

      if (!currentMonth) {
        checkAndUpdateMonth(dates);
      } else {
        const hasCurrentMonthDates = hasAvailableDatesInMonth(
          currentMonth,
          dates
        );
        const nextMonthDate = new Date(
          currentMonth.getFullYear(),
          currentMonth.getMonth() + 1,
          1
        );
        const hasNextMonthDates = hasAvailableDatesInMonth(
          nextMonthDate,
          dates
        );

        setShowAlert(!hasCurrentMonthDates);
        setNextMonthHasAvailability(hasNextMonthDates);
      }
    }
  }, [disponibilidad, currentMonth]);

  if (!currentMonth) {
    return null;
  }

  const daysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const startDay = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  };

  const formatDate = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };

  const isDateAvailable = (date) => {
    return availableDates.includes(formatDate(date));
  };

  const handleDateClick = (date) => {
    if (isDateAvailable(date)) {
      const formattedDate = formatDate(date);
      setSelectedDate(date);
      onDateChange(formattedDate, disponibilidad[formattedDate]);
    }
  };

  const handlePrevMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1)
    );
  };

  const renderCalendar = () => {
    const totalDays = daysInMonth(currentMonth);
    const startingDay = startDay(currentMonth);
    const days = [];

    for (let i = 0; i < (startingDay + 6) % 7; i++) {
      days.push(<Box key={`empty-${i}`} sx={{ width: "100%", height: 40 }} />);
    }

    for (let day = 1; day <= totalDays; day++) {
      const date = new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth(),
        day
      );
      const isAvailable = isDateAvailable(date);
      const isSelected =
        selectedDate && date.toDateString() === selectedDate.toDateString();

      days.push(
        <Box
          key={day}
          onClick={() => handleDateClick(date)}
          sx={{
            width: "100%",
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: isAvailable ? "pointer" : "not-allowed",
            borderRadius: "10%",
            backgroundColor: showAlert
              ? "#e8e9ee"
              : isSelected
              ? "#1e7aa8"
              : isAvailable
              ? "#b1dcf0"
              : "#e8e9ee",
            color: showAlert
              ? "#999999"
              : isSelected
              ? "#ffffff"
              : isAvailable
              ? "#333333"
              : "#999999",
          }}
        >
          <Typography sx={{ fontFamily: "Quicksand-Bold" }}>{day}</Typography>
        </Box>
      );
    }

    return days;
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          borderRadius: 5,
          p: 2,
          pr: isMobile ? 1 : 5,
          pl: isMobile ? 1 : 5,
        }}
      >
        {/* Navigation header */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Button onClick={handlePrevMonth} sx={{ minWidth: "auto" }}>
            <ChevronLeft sx={{ color: "#007FFF" }} />
          </Button>
          <Typography
            sx={{
              fontFamily: "Quicksand-Bold",
              fontSize: { xs: "18px", md: "20px" },
              color: "#007FFF",
            }}
          >
            {currentMonth.toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}
          </Typography>
          <Button onClick={handleNextMonth} sx={{ minWidth: "auto" }}>
            <ChevronRight sx={{ color: "#007FFF" }} />
          </Button>
        </Box>

        {/* Días de la semana */}
        <Grid container spacing={1} columns={7}>
          {["Lun", "Mar", "Mié", "Jue", "Vie", "Sáb", "Dom"].map((day) => (
            <Grid item key={day} xs={1}>
              <Typography
                align="center"
                sx={{ color: "#007FFF", fontFamily: "Quicksand-Bold" }}
              >
                {day}
              </Typography>
            </Grid>
          ))}
        </Grid>

        {/* Contenedor del grid de días */}
        <Box sx={{ position: "relative", mt: 1 }}>
          <Grid container spacing={1} columns={7}>
            {renderCalendar().map((day, index) => (
              <Grid item key={index} xs={1}>
                {day}
              </Grid>
            ))}
          </Grid>

          {showAlert && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 2,
                width: isMobile ? "60%" : "45%",
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  p: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: 30,
                }}
              >
                <Typography
                  sx={{
                    color: "#666666",
                    fontFamily: "Quicksand-Regular",
                    fontSize: "12px",
                  }}
                >
                  {`sin horas en ${getMonthName(currentMonth)}`}
                </Typography>
                {nextMonthHasAvailability && (
                  <Button
                    onClick={handleNextMonth}
                    sx={{
                      color: "#007FFF",
                      mt: -0.5,
                      p: 0,
                      textTransform: "none",
                      fontFamily: "Quicksand-Bold",
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    ver siguiente mes
                  </Button>
                )}
              </Paper>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomCalendar;
