import React, { useState, useEffect, useRef, useMemo } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Container, Grid, FormGroup } from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import callApi from "../../services/callApi";
import Loader from "../../components/Loader";
import { isMobile } from "react-device-detect";
import { initMercadoPago, Wallet } from "@mercadopago/sdk-react";
import logoImage from "../../assets/newiconos/Teradomus-gris.png";
import AddressInput from "./DireccionDatos";
import PaymentMethodBox from "./PaymentMethod.jsx";
import StyledSelect from "./Components/StyledSelect.jsx";
import RutValidationGrid from "./Components/ValidacionRut.jsx";

initMercadoPago(process.env.REACT_APP_PUBLIC_TOKEN, { locale: "es-CL" });

const isValidText = (text) => text.length >= 2;

const isValidEmail = (email) =>
  email.includes("@") && email.includes(".") && email.length >= 5;
const isValidPhone = (phone) => {
  // Remover el prefijo +56
  const numberWithoutPrefix = phone.replace("+56", "");

  // Si el número contiene asteriscos (*), considerarlo como válido si tiene 9 caracteres
  if (numberWithoutPrefix.includes("*")) {
    return numberWithoutPrefix.length === 9;
  }

  // Para números no enmascarados, mantener la validación original
  return numberWithoutPrefix.length === 9 && /^\d+$/.test(numberWithoutPrefix);
};
const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export default function DatosPaciente() {
  let history = useHistory();
  const location = useLocation();
  const MySwal = withReactContent(Swal);
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [preferenceId, setPreferenceId] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [text, setText] = useState("");

  const [mail, setMail] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [telefono, setTelefono] = useState("+56");
  const [rut, setRut] = React.useState("");
  const [formatoRut, setFormatoRut] = useState(true);
  const [birthDate, setBirthDate] = useState({ day: "", month: "", year: "" });
  const [sex, setSex] = useState("");
  const [prevision, setPrevision] = useState("");
  const [ApiCrear, setApiCrear] = useState(null);
  const [depto, setDepto] = React.useState("");
  const [isValidating, setIsValidating] = useState(false);
  const [isRutValidated, setIsRutValidated] = useState(false);
  const [validationResult, setValidationResult] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [savedData, setSavedData] = useState(null);
  const [id_paciente, setId_paciente] = useState(null);

  const paymentFormRef = useRef(null);

  const nombreCharactersRemaining = 50 - nombre.length;
  const apellidoCharactersRemaining = 50 - apellido.length;
  const mailCharactersRemaining = 50 - mail.length;
  const deptoCharactersRemaining = 50 - depto.length;
  const textCharactersRemaining = 250 - text.length;

  const isValidRut = (rut) => {
    if (formatoRut) {
      return rut.length >= 9 && rut.length <= 12;
    } else {
      return rut.length >= 1;
    }
  };

  const createPayment = async () => {
    setIsLoading(true);

    try {
      const apiData = {
        id_profesional_fantasma: location.state.id_kinesiologo,
        id_servicio_fantasma: location.state.id_servicios,
        fecha_agenda: location.state.dates,
        hora_agenda: location.state.hourss,
        paciente_email: mail,
      };
      const result = await callApi("/agenda/pagoCrear", "post", apiData);
      if (
        result &&
        result.message === "Preferencia MercadoPago creada" &&
        result.preference_id
      ) {
        setPreferenceId(result.preference_id);
        setPaymentId(result.payment_id);
        setApiCrear(result);
        handlePagarClick(result.payment_id, result.preference_id);
      } else {
        console.error("Respuesta inesperada:", result);
        MySwal.fire("Respuesta inesperada del servidor", "", "error");
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        console.error("Error de respuesta del servidor:", error.response.data);
        MySwal.fire(
          error.response.data.message || "Error del servidor",
          "",
          "error"
        );
      } else if (error.request) {
        console.error("No se recibió respuesta del servidor");
        MySwal.fire("No se pudo conectar con el servidor", "", "error");
      } else {
        console.error("Error al configurar la petición:", error.message);
        MySwal.fire("Error en la aplicación", "", "error");
      }
    }
  };

  function handleChangeNombre(event) {
    setNombre(event.target.value);
  }
  function handleChangeApellido(event) {
    setApellido(event.target.value);
  }
  const handleChangeDiagnostico = (event) => {
    setText(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setFormatoRut(!event.target.checked);
  };

  const handleChangeMail = (event) => {
    setMail(event.target.value);
  };

  const handleChangeRut = (event) => {
    const value = event.target.value;
    if (formatoRut) {
      const formattedRut = formatRut(value);
      setRut(formattedRut);
    } else {
      setRut(value);
    }
  };

  const formatRut = (rut) => {
    let cleanRut = rut.replace(/[^0-9kK]+/g, "").toUpperCase();

    if (cleanRut.length > 1) {
      const body = cleanRut.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      const dv = cleanRut.slice(-1);
      cleanRut = body + "-" + dv;
    }

    return cleanRut;
  };

  const handleChangeCelular = (event) => {
    const prefix = "+56";
    let inputValue = event.target.value;

    if (!inputValue.startsWith(prefix)) {
      inputValue = prefix + inputValue.replace(/\D/g, "");
    } else {
      inputValue = prefix + inputValue.slice(prefix.length).replace(/\D/g, "");
    }

    const maxLength = 9;
    inputValue = inputValue.slice(0, prefix.length + maxLength);

    setTelefono(inputValue);
  };
  const handleBirthDateChange = (field) => (event) => {
    setBirthDate({ ...birthDate, [field]: event.target.value });
  };

  const handleSexChange = (event) => {
    setSex(event.target.value);
  };

  const handlePrevisionChange = (event) => {
    setPrevision(event.target.value);
  };
  function handleChangeDepto(event) {
    setDepto(event.target.value);
  }

  const [direccion, setDireccion] = useState("");
  const [comuna, setComuna] = useState("");

  const textseciones = "✨ Ingrese los siguientes datos para agendar";

  const [textoVisible, setTextoVisible] = useState("");

  useEffect(() => {
    let currentIndex = 0;
    let textoAMostrar = textseciones;

    const interval = setInterval(() => {
      if (currentIndex <= textoAMostrar.length) {
        setTextoVisible(textoAMostrar.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 25);
    return () => {
      clearInterval(interval);
    };
  }, [textseciones]);

  const handleGoBack = () => {
    history.goBack();
  };

  const [shouldShowWallet, setShouldShowWallet] = useState(false);
  const [isWalletReady, setIsWalletReady] = useState(false);
  const walletRef = useRef(null);

  const handlePagarClick = async (payment_id, preference_id) => {
    const formatoValido =
      telefono.replace("+56", "").length === 9 &&
      (telefono.includes("*") || /^[\d\s+]+$/.test(telefono));

    if (!formatoValido) {
      MySwal.fire("Ingresa un número telefónico válido", "", "info");
      return;
    }
    setIsLoading(true);
    try {
      console.log("Iniciando handlePagarClick");
      const apiData = {
        id_profesional_fantasma: location.state.id_kinesiologo,
        id_servicio_fantasma: location.state.id_servicios,
        fecha_agenda: location.state.dates,
        hora_agenda: location.state.hourss,
        paciente_nombre: nombre,
        paciente_apellido: apellido,
        paciente_rut: rut,
        paciente_es_pasaporte: !formatoRut,
        paciente_email: mail,
        paciente_celular: telefono,
        paciente_direccion: direccion || "No especificada",
        paciente_motivo_consulta: text || "",
        preference_id: location.state.perfil_pago ? preference_id : null,
        payment_id: location.state.perfil_pago ? payment_id : null,
        paciente_sexo: sex,
        paciente_prevision: prevision,
        nacimiento_dia: birthDate.day.toString(),
        nacimiento_mes: birthDate.month,
        nacimiento_ano: birthDate.year.toString(),
        paciente_direccion_oficina: depto,
        id_paciente: id_paciente,
        paciente_datos_nuevos:
          validationResult?.paciente === "paciente_registrado"
            ? isEditing
              ? 1
              : 0
            : 0,
      };

      const result = await callApi("/agenda/agendarIntencion", "post", apiData);

      if (result?.statusCode === 200) {
        if (location.state.perfil_pago) {
          setShouldShowWallet(true);
          await waitForWalletReady();
          tryAutomaticPayment();
        } else {
          console.log("Redirigiendo a la página de éxito");
          history.push({
            pathname: "/pro/perfil/exito",
            state: { id_intencion: result.id_intencion },
          });
        }
      } else {
        console.error("La API respondió con un error:", result);
        MySwal.fire({
          title: "Error",
          text: `La API respondió con un error. Detalles: ${JSON.stringify(
            result
          )}`,
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error detallado al procesar la solicitud:", error);
      let errorMessage = "Hubo un problema al procesar su solicitud. ";
      if (error.response) {
        errorMessage += `Respuesta del servidor: ${JSON.stringify(
          error.response.data
        )}`;
      } else if (error.request) {
        errorMessage += "No se recibió respuesta del servidor.";
      } else {
        errorMessage += `Error: ${error.message}`;
      }
      MySwal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  const waitForWalletReady = () => {
    return new Promise((resolve) => {
      const checkWallet = () => {
        if (walletRef.current && walletRef.current.querySelector("button")) {
          setIsWalletReady(true);
          resolve();
        } else {
          setTimeout(checkWallet, 100);
        }
      };
      checkWallet();
    });
  };

  const tryAutomaticPayment = () => {
    if (walletRef.current) {
      const mpButton = walletRef.current.querySelector("button");
      if (mpButton) {
        console.log("Intentando clic automático en el botón de MercadoPago");
        mpButton.click();
      } else {
        console.error("No se encontró el botón de MercadoPago");
        showManualPaymentMessage();
      }
    } else {
      console.error("No se encontró la referencia del wallet");
      showManualPaymentMessage();
    }
  };

  const showManualPaymentMessage = () => {
    MySwal.fire({
      title: "Pago Manual Requerido",
      text: "No se pudo iniciar el pago automáticamente. Por favor, haga clic en el botón de pago que aparece debajo.",
      icon: "info",
      confirmButtonText: "Entendido",
    });
  };

  const isFormValid = useMemo(() => {
    return (
      isValidText(nombre) &&
      isValidText(apellido) &&
      isValidRut(rut) &&
      isValidEmail(mail) &&
      isValidPhone(telefono) &&
      direccion.trim() !== "" &&
      birthDate.day &&
      birthDate.month &&
      birthDate.year &&
      sex &&
      prevision
    );
  }, [
    nombre,
    apellido,
    rut,
    mail,
    telefono,
    direccion,
    birthDate,
    sex,
    prevision,
  ]);

  const formatearFechaSinAnio = (fecha) => {
    return fecha.slice(0, -5);
  };

  const transformModalidad = (modalidad) => {
    switch (modalidad) {
      case "consultorio":
        return "Consulta presencial";
      case "domicilio":
        return "Consulta a domicilio";
      case "online":
        return "Consulta online";
      default:
        return modalidad;
    }
  };
  const validateRut = async () => {
    setIsValidating(true);
    try {
      const url = `/agenda/validarIdentidad?id_profesional_fantasma=${
        location.state.id_kinesiologo
      }&paciente_rut=${rut}&paciente_es_pasaporte=${(!formatoRut).toString()}`;

      const result = await callApi(url);
      setValidationResult(result);

      if (result?.validacion === "rut_valido") {
        if (
          result.paciente === "paciente_registrado" &&
          Object.keys(result.datos_paciente || {}).length > 0
        ) {
          const pacienteData = result.datos_paciente;
          // Guardar los datos originales
          setSavedData(pacienteData);
          // Llenar los campos
          loadPatientData(pacienteData);
        }
        setIsRutValidated(true);
      } else {
        setIsRutValidated(false);
      }
    } catch (error) {
      console.error("Error validating RUT:", error);
      setValidationResult({ validacion: "rut_invalido" });
      setIsRutValidated(false);
    } finally {
      setIsValidating(false);
    }
  };

  const loadPatientData = (data) => {
    setId_paciente(data.id_paciente || null);
    setNombre(data.nombres || "");
    setApellido(data.apellidos || "");
    setMail(data.mail || "");
    setTelefono(data.celular || "+56");
    setDireccion(data.direccion || "");
    setDepto(data.departamento || "");
    setSex(data.sexo || "");
    setPrevision(data.prevision || "");
    setBirthDate({
      day: data.nacimiento_dia || "",
      month: data.nacimiento_mes || "",
      year: data.nacimiento_año || "",
    });
  };

  const handleEditData = () => {
    if (isEditing) {
      // Si estábamos editando, restaurar los datos guardados
      if (savedData) {
        loadPatientData(savedData);
      }
    } else {
      // Si vamos a editar, limpiar todos los campos
      setNombre("");
      setApellido("");
      setMail("");
      setTelefono("+56");
      setDireccion("");
      setDepto("");
      setSex("");
      setPrevision("");
      setBirthDate({ day: "", month: "", year: "" });
    }
    setIsEditing(!isEditing);
  };

  const isFieldDisabled = () => !isRutValidated || (savedData && !isEditing);

  const getFieldContainerStyle = (hasValue) => ({
    borderRadius: 2,
    overflow: "hidden",
    backgroundImage: isFieldDisabled()
      ? "none"
      : "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
    backgroundColor: isFieldDisabled() ? "#f5f5f5" : "transparent",
    opacity: isFieldDisabled() ? 0.6 : 1,
    p: 0.5,
  });

  const baseInputStyle = {
    border: "none",
    width: "100%",
    outline: "none",
    background: "transparent",
    fontFamily: "Quicksand-Regular",
    color: "#4F4F4F",
    fontSize: "16px",
    cursor: isFieldDisabled() ? "not-allowed" : "text",
  };

  const smoothScrollTo = (targetPosition, duration = 2000) => {
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    let startTime = null;

    const animation = (currentTime) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1);

      const easeInOutQuad = (t) => {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      };

      window.scrollTo(0, startPosition + distance * easeInOutQuad(progress));

      if (timeElapsed < duration) {
        requestAnimationFrame(animation);
      }
    };

    requestAnimationFrame(animation);
  };

  const scrollToPaymentWithDelay = () => {
    setTimeout(() => {
      if (paymentFormRef.current) {
        const targetPosition = paymentFormRef.current.offsetTop - 50;
        smoothScrollTo(targetPosition, 2000);
        paymentFormRef.current.focus({ preventScroll: true });
      }
    }, 5000);
  };

  useEffect(() => {
    if (
      isRutValidated &&
      validationResult?.paciente === "paciente_registrado"
    ) {
      scrollToPaymentWithDelay();
    }
  }, [isRutValidated, validationResult]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <ScrollToTop />
      <img
        src={logoImage}
        alt="Teradomus Logo"
        style={{
          width: isMobile ? "150px" : "200px",
          height: "auto",
          paddingTop: "15px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          padding: 15,
        }}
      />
      <Box
        sx={{
          width: isMobile ? "95%" : "40%",
          mx: "auto",
          bgcolor: "#fbfafe",
          boxShadow: 1,
          marginTop: 2,
          borderRadius: 2,
          p: isMobile ? 2 : 3,
          paddingLeft: isMobile ? 0 : 10,
          paddingRight: isMobile ? 0 : 10,
        }}
      >
        <Typography sx={{ ml: 2, fontFamily: "Quicksand-Light" }}>
          Estas agendando con:
        </Typography>
        <Typography
          sx={{
            ml: 2,
            mt: -0.5,
            fontFamily: "Quicksand-Bold",
            fontSize: { xs: "20px", md: "20px", lg: "22px" },
          }}
        >
          {location.state.titulo}
        </Typography>
        <Typography sx={{ ml: 2, fontFamily: "Quicksand-Regular", mt: -0.5 }}>
          {location.state.profesion}
        </Typography>
        <Typography sx={{ ml: 2, fontFamily: "Quicksand-Light", mt: 1 }}>
          El servicio de:
        </Typography>
        <Typography
          sx={{
            ml: 2,
            mt: -0.5,
            fontFamily: "Quicksand-Bold",
            fontSize: { xs: "20px", md: "20px", lg: "22px" },
          }}
        >
          {location.state.servicio_nombre}
        </Typography>
        <Typography sx={{ ml: 2, fontFamily: "Quicksand-Regular", mt: -0.5 }}>
          {location.state.servicio_precios}
        </Typography>
        <Typography sx={{ ml: 2, fontFamily: "Quicksand-Regular", mt: -0.5 }}>
          {transformModalidad(location.state.servicio_modalidad)}
        </Typography>
        <Typography
          sx={{
            ml: 2,
            color: "#8546b1",
            fontFamily: "Quicksand-Bold",
            mt: -0.5,
          }}
        >
          {formatearFechaSinAnio(location.state.fecha_formateada)} {"-"}{" "}
          {location.state.hourss}
        </Typography>
        <Typography
          sx={{ ml: 2, color: "#8546b1", fontFamily: "Quicksand-Bold" }}
        ></Typography>
      </Box>
      <Typography
        textAlign={"center"}
        sx={{
          color: "#007FFF",
          mt: { xs: 1, lg: 3 },
          fontFamily: "Quicksand-Bold",
          fontSize: { xs: "20px", md: "20px", lg: "26px" },
        }}
      >
        Datos del Paciente
      </Typography>
      <Box
        sx={{
          width: { lg: "50.4%", xs: "95%" },
          backgroundColor: "white",
          borderRadius: 3,
          boxShadow: "5px 5px 6px rgba(0, 0, 0, 0.14)",
          border: "1px solid rgba(0, 0, 0, 0.04)",
          mt: { lg: 3, xs: 2 },
          mb: { lg: 5, xs: 5 },
          margin: "0 auto",
        }}
      >
        <FormGroup>
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} lg={8}>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    textAlign={"center"}
                    sx={{
                      mt: { xs: 2, lg: 3 },
                      mb: { xs: 3, lg: 5 },
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "16px", lg: "16px" },
                    }}
                  >
                    {textoVisible}
                  </Typography>
                  <RutValidationGrid
                    rut={rut}
                    handleChangeRut={handleChangeRut}
                    isRutValidated={isRutValidated}
                    formatoRut={formatoRut}
                    validateRut={validateRut}
                    isValidating={isValidating}
                    isValidRut={isValidRut}
                    handleCheckboxChange={handleCheckboxChange}
                    validationResult={validationResult}
                  />
                  {/* Nombre */}
                  <Grid item xs={12} lg={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          pt: 1,
                          fontFamily: isValidText(nombre)
                            ? "Quicksand-Bold"
                            : "Quicksand-Regular",
                          fontSize: { xs: "16px", md: "20px", lg: "20px" },
                          color: isFieldDisabled()
                            ? "#9e9e9e"
                            : isValidText(nombre)
                            ? "#2669A8"
                            : "#4F4F4F",
                        }}
                      >
                        {isValidText(nombre) ? "Nombre" : "Nombre*"}
                      </Typography>
                      {validationResult?.paciente === "paciente_registrado" &&
                        validationResult?.datos_paciente && (
                          <Button
                            onClick={handleEditData}
                            sx={{
                              fontFamily: isEditing
                                ? "Quicksand-Bold"
                                : "Quicksand-Regular",
                              fontSize: "14px",
                              color: isEditing ? "#8546b1" : "#9e9e9e",
                              "&:hover": {
                                backgroundColor: isEditing
                                  ? "rgba(135, 83, 177, 0.1)"
                                  : "rgba(158, 158, 158, 0.1)",
                              },
                              textTransform: "none",
                            }}
                          >
                            {isEditing
                              ? " ⬅️ Mantener datos guardados"
                              : "Editar datos"}
                          </Button>
                        )}
                    </Box>
                    <Box sx={getFieldContainerStyle(!!nombre)}>
                      <input
                        value={nombre}
                        onChange={handleChangeNombre}
                        disabled={isFieldDisabled()}
                        maxLength={50}
                        style={{
                          ...baseInputStyle,
                          opacity: isFieldDisabled() ? 0.6 : 1,
                        }}
                      />
                    </Box>
                    {nombreCharactersRemaining <= 10 && (
                      <Typography
                        variant="caption"
                        sx={{
                          textAlign: "right",
                          color:
                            nombreCharactersRemaining <= 0 ? "red" : "inherit",
                        }}
                      >
                        {nombreCharactersRemaining} caracteres restantes
                      </Typography>
                    )}
                  </Grid>
                  {/* Apellido */}
                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily: isValidText(apellido)
                          ? "Quicksand-Bold"
                          : "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color: isFieldDisabled()
                          ? "#9e9e9e"
                          : isValidText(apellido)
                          ? "#2669A8"
                          : "#4F4F4F",
                      }}
                    >
                      {isValidText(apellido) ? "Apellido" : "Apellido*"}
                    </Typography>
                    <Box sx={getFieldContainerStyle(!!apellido)}>
                      <input
                        onChange={handleChangeApellido}
                        maxLength={50}
                        disabled={isFieldDisabled()}
                        value={apellido}
                        style={{
                          ...baseInputStyle,
                          opacity: isFieldDisabled() ? 0.6 : 1,
                        }}
                      />
                    </Box>
                    {apellidoCharactersRemaining <= 10 && (
                      <Typography
                        variant="caption"
                        sx={{
                          textAlign: "right",
                          color:
                            apellidoCharactersRemaining <= 0
                              ? "red"
                              : "inherit",
                        }}
                      >
                        {apellidoCharactersRemaining} caracteres restantes
                      </Typography>
                    )}
                  </Grid>
                  {/* Fecha de Nacimiento */}
                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily:
                          birthDate.day && birthDate.month && birthDate.year
                            ? "Quicksand-Bold"
                            : "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color: isFieldDisabled()
                          ? "#9e9e9e"
                          : birthDate.day && birthDate.month && birthDate.year
                          ? "#2669A8"
                          : "#4F4F4F",
                      }}
                    >
                      {birthDate.day && birthDate.month && birthDate.year
                        ? "Fecha de nacimiento"
                        : "Fecha de nacimiento*"}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 1,
                        opacity: isFieldDisabled() ? 0.6 : 1,
                      }}
                    >
                      {/* Día */}
                      <Box
                        sx={{
                          width: "16.6%",
                          borderRadius: 1.5,
                          backgroundImage: isFieldDisabled()
                            ? "none"
                            : "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                          backgroundColor: isFieldDisabled()
                            ? "#f5f5f5"
                            : "transparent",
                        }}
                      >
                        <StyledSelect
                          value={birthDate.day}
                          onChange={handleBirthDateChange("day")}
                          options={[...Array(31)].map((_, i) => ({
                            value: i + 1,
                            label: i + 1,
                          }))}
                          placeholder="Día"
                          disabled={isFieldDisabled()}
                          sx={{
                            "& .MuiSelect-select": {
                              cursor: isFieldDisabled()
                                ? "not-allowed"
                                : "pointer",
                              opacity: isFieldDisabled() ? 0.6 : 1,
                            },
                          }}
                        />
                      </Box>

                      {/* Mes */}
                      <Box
                        sx={{
                          width: "50%",
                          borderRadius: 1.5,
                          backgroundImage: isFieldDisabled()
                            ? "none"
                            : "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                          backgroundColor: isFieldDisabled()
                            ? "#f5f5f5"
                            : "transparent",
                        }}
                      >
                        <StyledSelect
                          value={birthDate.month}
                          onChange={handleBirthDateChange("month")}
                          options={months.map((month) => ({
                            value: month,
                            label:
                              month.charAt(0).toUpperCase() + month.slice(1),
                          }))}
                          placeholder="Mes"
                          disabled={isFieldDisabled()}
                          sx={{
                            "& .MuiSelect-select": {
                              cursor: isFieldDisabled()
                                ? "not-allowed"
                                : "pointer",
                              opacity: isFieldDisabled() ? 0.6 : 1,
                            },
                          }}
                        />
                      </Box>

                      {/* Año */}
                      <Box
                        sx={{
                          width: "33%",
                          borderRadius: 1.5,
                          backgroundImage: isFieldDisabled()
                            ? "none"
                            : "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                          backgroundColor: isFieldDisabled()
                            ? "#f5f5f5"
                            : "transparent",
                        }}
                      >
                        <StyledSelect
                          value={birthDate.year}
                          onChange={handleBirthDateChange("year")}
                          options={[...Array(125)].map((_, i) => ({
                            value: 2024 - i,
                            label: 2024 - i,
                          }))}
                          placeholder="Año"
                          disabled={isFieldDisabled()}
                          sx={{
                            "& .MuiSelect-select": {
                              cursor: isFieldDisabled()
                                ? "not-allowed"
                                : "pointer",
                              opacity: isFieldDisabled() ? 0.6 : 1,
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily: sex
                          ? "Quicksand-Bold"
                          : "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color: isFieldDisabled()
                          ? "#9e9e9e"
                          : sex
                          ? "#2669A8"
                          : "#4F4F4F",
                      }}
                    >
                      {sex ? "Sexo" : "Sexo*"}
                    </Typography>
                    <Box
                      sx={{
                        borderRadius: 2,
                        overflow: "hidden",
                        backgroundImage: isFieldDisabled()
                          ? "none"
                          : "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                        backgroundColor: isFieldDisabled()
                          ? "#f5f5f5"
                          : "transparent",
                        opacity: isFieldDisabled() ? 0.6 : 1,
                        p: 0.1,
                      }}
                    >
                      <StyledSelect
                        value={sex}
                        onChange={handleSexChange}
                        disabled={isFieldDisabled()}
                        options={[
                          { value: "Femenino", label: "Femenino" },
                          { value: "Masculino", label: "Masculino" },
                          {
                            value: "Prefiero no decirlo",
                            label: "Prefiero no decirlo",
                          },
                        ]}
                        placeholder="Seleccione"
                        sx={{
                          "& .MuiSelect-select": {
                            cursor: isFieldDisabled()
                              ? "not-allowed"
                              : "pointer",
                            opacity: isFieldDisabled() ? 0.6 : 1,
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                  {/* Campo de Email */}
                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily: isValidEmail(mail)
                          ? "Quicksand-Bold"
                          : "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color: isFieldDisabled()
                          ? "#9e9e9e"
                          : isValidEmail(mail)
                          ? "#2669A8"
                          : "#4F4F4F",
                      }}
                    >
                      {isValidEmail(mail) ? "Email" : "Email*"}
                    </Typography>
                    <Box sx={getFieldContainerStyle(!!mail)}>
                      <input
                        type="email"
                        value={mail}
                        maxLength={50}
                        onChange={handleChangeMail}
                        disabled={isFieldDisabled()}
                        style={{
                          ...baseInputStyle,
                          opacity: isFieldDisabled() ? 0.6 : 1,
                        }}
                      />
                    </Box>
                    {mailCharactersRemaining <= 10 && (
                      <Typography
                        variant="caption"
                        sx={{
                          textAlign: "right",
                          color:
                            mailCharactersRemaining <= 0 ? "red" : "inherit",
                        }}
                      >
                        {mailCharactersRemaining} caracteres restantes
                      </Typography>
                    )}
                  </Grid>
                  {/* Campo de Teléfono */}
                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily: isValidPhone(telefono)
                          ? "Quicksand-Bold"
                          : "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color: isFieldDisabled()
                          ? "#9e9e9e"
                          : isValidPhone(telefono)
                          ? "#2669A8"
                          : "#4F4F4F",
                      }}
                    >
                      {isValidPhone(telefono) ? "Teléfono" : "Teléfono*"}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: 2,
                        overflow: "hidden",
                        backgroundImage: isFieldDisabled()
                          ? "none"
                          : "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                        backgroundColor: isFieldDisabled()
                          ? "#f5f5f5"
                          : "transparent",
                        opacity: isFieldDisabled() ? 0.6 : 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: "50px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRight: "4px solid #fff",
                          opacity: isFieldDisabled() ? 0.6 : 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Quicksand-Regular",
                            color: isFieldDisabled() ? "#9e9e9e" : "#4F4F4F",
                            fontSize: "16px",
                          }}
                        >
                          +56
                        </Typography>
                      </Box>
                      <input
                        value={telefono.replace("+56", "")}
                        onChange={handleChangeCelular}
                        disabled={isFieldDisabled()}
                        style={{
                          border: "none",
                          flex: 1,
                          outline: "none",
                          background: "transparent",
                          paddingLeft: "10px",
                          fontFamily: "Quicksand-Regular",
                          color: "#4F4F4F",
                          fontSize: "16px",
                          height: "28px",
                          cursor: isFieldDisabled() ? "not-allowed" : "text",
                          opacity: isFieldDisabled() ? 0.6 : 1,
                        }}
                      />
                    </Box>
                  </Grid>
                  {/* Previsión */}
                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily: prevision
                          ? "Quicksand-Bold"
                          : "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color: isFieldDisabled()
                          ? "#9e9e9e"
                          : prevision
                          ? "#2669A8"
                          : "#4F4F4F",
                      }}
                    >
                      {prevision ? "Previsión" : "Previsión*"}
                    </Typography>
                    <Box
                      sx={{
                        borderRadius: 2,
                        overflow: "hidden",
                        backgroundImage: isFieldDisabled()
                          ? "none"
                          : "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                        backgroundColor: isFieldDisabled()
                          ? "#f5f5f5"
                          : "transparent",
                        opacity: isFieldDisabled() ? 0.6 : 1,
                        p: 0.1,
                      }}
                    >
                      <StyledSelect
                        value={prevision}
                        onChange={handlePrevisionChange}
                        disabled={isFieldDisabled()}
                        options={[
                          { value: "Isapre", label: "Isapre" },
                          { value: "Fonasa", label: "Fonasa" },
                          {
                            value: "Particular/Otro",
                            label: "Particular/Otro",
                          },
                        ]}
                        placeholder="Seleccione"
                        sx={{
                          "& .MuiSelect-select": {
                            cursor: isFieldDisabled()
                              ? "not-allowed"
                              : "pointer",
                            opacity: isFieldDisabled() ? 0.6 : 1,
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                  {/* Dirección Component */}
                  <AddressInput
                    onAddressChange={setDireccion}
                    onComunaChange={setComuna}
                    disabled={isFieldDisabled()}
                    direccion={direccion}
                  />
                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily: "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color: isFieldDisabled() ? "#9e9e9e" : "#4F4F4F",
                      }}
                    >
                      Depto, oficina u otro - opcional
                    </Typography>
                    <Box sx={getFieldContainerStyle(!!depto)}>
                      <input
                        onChange={handleChangeDepto}
                        disabled={isFieldDisabled()}
                        value={depto}
                        maxLength={50}
                        style={{
                          ...baseInputStyle,
                          opacity: isFieldDisabled() ? 0.6 : 1,
                        }}
                      />
                    </Box>
                    {deptoCharactersRemaining <= 10 && (
                      <Typography
                        variant="caption"
                        sx={{
                          textAlign: "left",
                          display: "block",
                          color:
                            deptoCharactersRemaining <= 0 ? "red" : "inherit",
                        }}
                      >
                        {deptoCharactersRemaining} caracteres restantes
                      </Typography>
                    )}
                  </Grid>
                  {/* Motivo de consulta */}
                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily: "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color: !isRutValidated ? "#9e9e9e" : "#4F4F4F",
                      }}
                    >
                      Motivo de consulta - opcional
                    </Typography>
                    <Box
                      sx={{
                        borderRadius: 2,
                        backgroundImage: !isRutValidated
                          ? "none"
                          : "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                        backgroundColor: !isRutValidated
                          ? "#f5f5f5"
                          : "transparent",
                        opacity: !isRutValidated ? 0.6 : 1,
                        p: 0.5,
                        zIndex: -9999,
                      }}
                    >
                      <TextareaAutosize
                        onChange={handleChangeDiagnostico}
                        value={text}
                        maxLength={250}
                        disabled={!isRutValidated}
                        style={{
                          border: "none",
                          width: "96%",
                          outline: "none",
                          background: "transparent",
                          fontFamily: "Quicksand-Regular",
                          color: "#4F4F4F",
                          fontSize: "16px",
                          resize: "none",
                          height: "120px",
                          cursor: !isRutValidated ? "not-allowed" : "text",
                        }}
                      />
                    </Box>
                    {textCharactersRemaining <= 10 && (
                      <Typography
                        variant="caption"
                        sx={{
                          textAlign: "right",
                          color: !isRutValidated
                            ? "#9e9e9e"
                            : textCharactersRemaining <= 0
                            ? "red"
                            : "inherit",
                        }}
                      >
                        {textCharactersRemaining} caracteres restantes
                      </Typography>
                    )}
                  </Grid>
                  {/* Texto obligatorio */}
                  <Typography
                    sx={{
                      textAlign: "right",
                      mb: { xs: 3, lg: 4 },
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "14px", md: "16px", lg: "16px" },
                      color: !isRutValidated ? "#9e9e9e" : "#4F4F4F",
                    }}
                  >
                    * Obligatorio
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </FormGroup>
        <Container
          ref={paymentFormRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: { xs: 3, lg: 2 },
            mb: { xs: 3, lg: 2 },
            width: { xs: "100%", lg: "55%" },
            position: "relative",
          }}
          tabIndex={-1}
        >
          {location.state.perfil_pago ? (
            <>
              <PaymentMethodBox />
              <Button
                sx={{
                  fontSize: { xs: "14px", md: "16px", lg: "16px" },
                  fontFamily: "Quicksand-Bold",
                  backgroundColor: "#8753b1",
                  "&:hover": { backgroundColor: "#651d87" },
                  borderRadius: 2,
                  zIndex: 2,
                  width: "100%",
                  paddingTop: 2,
                  paddingBottom: 2,
                }}
                variant="contained"
                disabled={!isFormValid || isWalletReady}
                onClick={createPayment}
              >
                {isWalletReady
                  ? "Proceder al Pago"
                  : `Pagar ${location.state.servicio_precios}`}
              </Button>
              {shouldShowWallet && (
                <div
                  ref={walletRef}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: isWalletReady ? 3 : 1,
                  }}
                >
                  <Wallet
                    initialization={{ preferenceId: preferenceId }}
                    customization={{
                      texts: { action: "pay", valueProp: "security_safety" },
                      visual: {
                        borderRadius: "25px",
                        buttonBackground: "blue",
                        hideValueProp: true,
                      },
                    }}
                  />
                </div>
              )}
            </>
          ) : (
            <Button
              sx={{
                fontSize: { xs: "14px", md: "16px", lg: "16px" },
                fontFamily: "Quicksand-Bold",
                backgroundColor: "#8753b1",
                "&:hover": { backgroundColor: "#651d87" },
                borderRadius: 3,
                width: "100%",
              }}
              variant="contained"
              disabled={!isFormValid}
              onClick={handlePagarClick}
            >
              Agendar
            </Button>
          )}
        </Container>
        <Button
          onClick={handleGoBack}
          sx={{
            fontFamily: "Quicksand-Bold",
            fontSize: { xs: "14px", md: "16px", lg: "16px" },
            color: "#007FFF",
            "&:hover": { backgroundColor: "rgba(135, 83, 177, 0.1)" },
            borderRadius: 3,
            textTransform: "none",
          }}
        >
          Volver
        </Button>
      </Box>
    </>
  );
}
